import React from "react";
import { graphql, Link } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { PostTeaser } from "../components";
import { normalizeArticles } from "../utils/utils";
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const CategoryPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: any;
}) => {
  const { all, published } = data;
  const { category } = pageContext;
  const articles =
    activeEnv === "development"
      ? normalizeArticles(all.edges.map((o: any) => o.node))
      : normalizeArticles(published.edges.map((o: any) => o.node));

  return (
    <LayoutMain isBlogPost={false} title={`Articles About ${category}`}>
      <div className="posts">
        <h1 className="page-title">{category}</h1>
        <h2>
          <Link to="/categories">View All Categories</Link>
        </h2>
        {articles.map((post) => (
          <PostTeaser key={post.id} article={post} />
        ))}
      </div>
    </LayoutMain>
  );
};

export const ArticlesByCategory = graphql`
  query ($categorySlug: String!) {
    published: allFile(
      filter: {
        childMdx: {
          frontmatter: {
            categorySlug: { eq: $categorySlug }
            status: { eq: "published" }
          }
        }
        sourceInstanceName: { eq: "articles" }
      }
      sort: { fields: [childMdx___frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          childMdx {
            excerpt
            frontmatter {
              title
              slug
              date
              category
              categorySlug
              author
              description
              status
              bannerAlt
              keywords
              metaDescription
              metaTitle
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    all: allFile(
      filter: {
        childMdx: { frontmatter: { categorySlug: { eq: $categorySlug } } }
        sourceInstanceName: { eq: "articles" }
      }
      sort: { fields: [childMdx___frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          childMdx {
            excerpt
            frontmatter {
              title
              slug
              date
              category
              categorySlug
              author
              description
              status
              bannerAlt
              keywords
              metaDescription
              metaTitle
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
